.slick-prev:before
{
    content: '';
}

.slick-next:before
{
    content: '';
}
[dir='rtl'] .slick-next:before
{
    content: '';
}

