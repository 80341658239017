::-webkit-scrollbar {width: 4px;}
::-webkit-scrollbar-button {width: 0; height: 0;}
::-webkit-scrollbar-thumb {border-radius:2px; background-color:#999999;}
::-webkit-scrollbar-track {background:#ddd;}
* {box-sizing:border-box; margin:0; padding:0; list-style:none;}

ul,ol{list-style: none;}
html,body{width: 100%; height: 100%;}

a,
button,
a input,
.thumbBox,
.catalogDown input{cursor: pointer;}
.popUpOpen_btnBox,
.popUpBox{font-family: 'Pretendard',sans-serif;}

/* 버튼박스 */
.popUpOpen_btnBox{border-radius: 20px 20px 0 0; box-shadow: rgb(0 0 0 / 0.1) 1px 1px 5px 1px; display: flex; position: fixed; bottom: 0; right: 60px; z-index: 9999;}


.popUpOpen_btnBox button{font-size: 15px; font-weight: 500; color: #666666; line-height: 22px; background-color: #fff; border: none; position: relative; padding: 20px 50px 20px 80px;}
.popUpOpen_btnBox button:not(:last-child):after{content: ''; display: block; background-color: #DDDDDD; width: 1px; height: 20px; position: absolute; right: 0; top: 50%; transform: translate(-50%, -50%);}
.popUpOpen_btnBox button:not(:last-child).active:after{display: none;}
.popUpOpen_btnBox button span{left: 50px; width: 24px; height: 24px; position: absolute; background-repeat: no-repeat; background-size: contain; background-position: center;}
.popUpOpen_btnBox .popUp_btn1{border-radius: 20px 0 0 0;}
.popUpOpen_btnBox .popUp_langBtn{border-radius: 0 20px 0 0;}
.popUpOpen_btnBox .popUp_btn1 span{background-image: url('/public/assets/images/modelingCtrl/popUp_btn1.png');}
.popUpOpen_btnBox .popUp_btn2 span{background-image: url('/public/assets/images/modelingCtrl/popUp_btn2.png');}
.popUpOpen_btnBox .popUp_btn3 span{background-image: url('/public/assets/images/modelingCtrl/popUp_btn3.png');}
.popUpOpen_btnBox .popUp_btn4 span{background-image: url('/public/assets/images/modelingCtrl/popUp_btn4.png');}
.popUpOpen_btnBox .popUp_langBtn span{top: 50%; transform: translateY(-50%); background-position: center; background-repeat: no-repeat; background-size: contain; width: 32px; height: 32px;}
.popUpOpen_btnBox .popUp_langBtn.popUp_lang_ko span{background-image: url('/public/assets/images/modelingCtrl/lang_ko.png');}
.popUpOpen_btnBox .popUp_langBtn.popUp_lang_en span{background-image: url('/public/assets/images/modelingCtrl/lang_en.png');}
.popUpOpen_btnBox button.active{color: #fff;}
.popUpOpen_btnBox .popUp_btn1.active{background-color: #035EAF;}
.popUpOpen_btnBox .popUp_btn2.active{background-color: #00AC72;}
.popUpOpen_btnBox .popUp_btn3.active{background-color: #666666;}
.popUpOpen_btnBox .popUp_btn4.active{background-color: #FFCC00;}
.popUpOpen_btnBox .popUp_btn1.active span{background-image: url('/public/assets/images/modelingCtrl/popUp_btn1_active.png');}
.popUpOpen_btnBox .popUp_btn2.active span{background-image: url('/public/assets/images/modelingCtrl/popUp_btn2_active.png');}
.popUpOpen_btnBox .popUp_btn3.active span{background-image: url('/public/assets/images/modelingCtrl/popUp_btn3_active.png');}
.popUpOpen_btnBox .popUp_btn4.active span{background-image: url('/public/assets/images/modelingCtrl/popUp_btn4_active.png');}

.popUpClose_btn{position: absolute; right: 20px; top: 20px; padding: 0; border: none; font-size: 0; line-height: 0; background-color: transparent;}
/* 버튼박스 Fin */


.popUpBox > .popUp_4.active{width: 100%; right: 0; height: calc(100% - 30px); bottom: 70px;}

.popUpBox > div{background-color: #fff; box-sizing: border-box; z-index: 1; z-index: 999; position: fixed; right: 60px; bottom: -100%; transition: bottom 0.5s; width: 800px; height: 760px; box-shadow: rgb(0 0 0 / 0.1) 1px 1px 5px 1px; border-radius: 20px;}
.popUpBox > div.active{z-index: 9999; bottom: 100px;}
.popUpBox .content{display: none;}
.popUpBox .content.active{display: block;}
.popUpBox > div > div{overflow-y: hidden; overflow-x: auto;}


.popUpBox > div .iconBox{display: flex; padding: 30px 40px 0 40px; box-sizing: border-box; border-bottom: 1px solid #E4EAEF;}
.popUpBox > div .iconBox li{cursor: pointer;}
.popUpBox > div .iconBox li > div{width: 40px;  height: 30px; position: relative;}
.popUpBox > div .iconBox li.active > div:after{content: ''; display: block; height: 2px; width: 40px; background-color: #256DCB; position: absolute; bottom: -1px;}
.popUpBox > div .iconBox li:not(:first-child){margin-left: 40px;}
.popUpBox > div .iconBox li .icon{height: 18px; width: 100%; position: absolute; left: 50%; transform: translateX(-50%); transition: background 0.1s; position: absolute; background-repeat: no-repeat; background-position: center; background-size: contain;}
.popUpBox .iconBox li .txt{line-height: 15px; padding-bottom: 10px; display: none; position: absolute; top: -13px; font-size: 13px; font-weight: 400; background-color: #14436D; border-radius: 5px; padding: 6px 12px; color: #fff;}
.popUpBox .iconBox li .txt:after{content: ''; position: absolute; background: url('/public/assets/images/modelingCtrl/popUp_triangle.png') center / contain no-repeat; width: 12px; height: 10px; bottom: -8px; left: 10px;}
.popUpBox .iconBox li:hover .txt,
.popUpBox .iconBox li.active .txt{display: block;}

/* .popUpBox .contentBox{padding: 20px; box-sizing: border-box;} */

.popUpBox .contentBox .titleBox{margin-bottom: 25px; position: relative; background-color: #F6F6F6; padding: 18px 0; border-radius: 5px;}
.popUpBox .contentBox .titleBox span{position: absolute; background: url('/public/assets/images/modelingCtrl/popUp1_cube.png') center / contain no-repeat; width: 18px; height: 21px; transform: translateY(-50%); top: 50%; left: 25px;}
.popUpBox .contentBox .titleBox p{text-align: center; color: #555555; font-size: 18px; font-weight: 600;}
.popUpBox .contentBox .txtBox textarea{outline: none; resize: none; padding: 10px; box-sizing: border-box; width: 760px; height: 340px; border: 1px solid #EEEEEE; border-radius: 5px; padding: 25px 30px; box-sizing: border-box;}
.popUpBox .contentBox .txtBox p{font-size: 16px; line-height: 26px; color: #666666; font-weight: 400;}
.popUpBox .contentBox .txtBox p span{display: block; line-height: 26px;}

.popUpBox .contentBox .videoBox{margin-bottom: 25px;}
.popUpBox .contentBox .videoBox .video iframe{width: 100%; height: 444px;}
.popUpBox .contentBox .videoBox .none{border: 1px solid #E4EAEF; width: 100%; height: 444px; position: relative;}
.popUpBox .contentBox .videoBox .none p{text-align: center; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);}
.popUpBox .contentBox .video_txtBox{padding: 0 20px; box-sizing: border-box;}
.popUpBox .contentBox .video_txtBox p{color: #666666; font-size: 16px; line-height: 23px; font-weight: 300;}
.popUpBox .contentBox .video_txtBox p.title{color: #555555; font-weight: 600; font-size: 20px; margin-bottom: 15px;}


.popUpBox .catalogDown input,
.popUpBox .catalogDown a{width: 100%;}


/* popUp_1 */

.popUpBox .popUp_1 .iconBox li:nth-child(1) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu1.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(1).active .icon,
.popUpBox .popUp_1 .iconBox li:nth-child(1):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu1_active.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(2) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu2.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(2).active .icon,
.popUpBox .popUp_1 .iconBox li:nth-child(2):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu2_active.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(3) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu3.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(3).active .icon,
.popUpBox .popUp_1 .iconBox li:nth-child(3):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu3_active.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(4) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu4.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(4).active .icon,
.popUpBox .popUp_1 .iconBox li:nth-child(4):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu4_active.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(5) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu5.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(5).active .icon,
.popUpBox .popUp_1 .iconBox li:nth-child(5):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu5_active.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(6) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu6.png');}
.popUpBox .popUp_1 .iconBox li:nth-child(6).active .icon,
.popUpBox .popUp_1 .iconBox li:nth-child(6):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu6_active.png');}


.popUpBox .catalogDown{display: flex; justify-content: space-between; border-radius: 5px; padding: 25px 30px; box-sizing: border-box; margin-top: 10px; width: 100%; border: 1px solid #EEEEEE;}
.popUpBox .catalogDown > div{align-items: center; display: flex;}
.popUpBox .catalogDown > div:nth-child(1){width: 100%;}
.popUpBox .catalogDown span{cursor: pointer;}
.popUpBox .catalogDown button{border: none; background-color: transparent; margin: 0; margin-left: 20px; line-height: 0; font-size: 0; padding: 0;}
.popUpBox .catalogDown input{margin-left: 15px; box-sizing: border-box; font-size: 15px; background-color: #fff; color: #666666; width: 100%; border: none; outline: none;}
/* .popUpBox .catalogDown a{width: 100%; display: block; cursor: pointer;} */


.popUpBox .popUp_1 .contentBox .content{padding: 20px 20px 40px 20px; box-sizing: border-box; height: 690px; overflow-y: auto;}
.popUpBox .popUp_1 .contentBox .content_1 .txtBox{padding: 0 20px; box-sizing: border-box; border: none;}

.popUpBox .popUp_1 .contentBox .content_3{height: 690px; overflow-y: auto;}


/* .popUpBox .popUp_1 .content_4 ul{display: flex; flex-wrap: wrap;}
.popUpBox .popUp_1 .content_4 ul li:not(:nth-child(3n)){margin-right: 2%;}
.popUpBox .popUp_1 .content_4 ul li{width: 32%; margin-bottom: 2%;}
.popUpBox .popUp_1 .content_4 ul li img{width: 100%;} */

/* .popUpBox .popUp_1 .content_2 .videoBox{padding: 30px 40px 80px 40px; box-sizing: border-box;} */
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap{margin-top: 10px; display: flex; width: 100%; flex-wrap: wrap;}
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap li{margin: 0; padding: 0; margin-bottom: 5%; position: relative; width: 100%; height: 100%; padding-bottom: 60px;}
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap li:not(:nth-child(2n)){margin-right: 2%;}
.popUpBox .popUp_1 .content_2 .videoBox > div:nth-child(1) > div:not(:last-child){margin-bottom: 60px;}
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap .videoArea{overflow: hidden; border: 1px solid #EEEEEE; border-radius: 5px; height: 445px; width: 100%; background-color: #F9F9F9;}
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap .videoArea iframe{width: 100% !important; height: 445px !important;}
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap .videoTitleBox{position: absolute; bottom: 0; left: 0; width: 100%;}
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap .videoTitleBox p{font-size: 14px; color: #666666; font-weight: 400; width: 100%; display: block;}
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap .videoTitleBox .video_title{font-size: 20px; color: #555555; font-weight: 600; margin-bottom: 15px;}
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap .videoTitleBox > div:nth-of-type(2){display: flex;}
.popUpBox .popUp_1 .content_2 .videoBox .videoWrap .videoTitleBox button{margin-left: 5px; line-height: 0; font-size: 0; padding: 0; background-color: transparent; border: none;}

.popUpBox .popUp_1 .content_2 .videoWrap .videoArea iframe{width: 100%; height: 445px; margin-bottom: 25px;}
.popUpBox .popUp_1 .content_2 .videoWrap .videoTitleBox .video_title{font-size: #555555; font-size: 20px; font-weight: 600; margin-bottom: 15px;}
.popUpBox .popUp_1 .content_2 .videoWrap .videoTitleBox .video_text{font-size: 16px; font-weight: 400; color: #666666;}
.popUpBox .popUp_1 .content_2 .videoWrap li{margin-bottom: 5%;}


.popUpBox .popUp_1 .content_4{padding: 20px; box-sizing: border-box; height: 695px; overflow-y: auto;}
.popUpBox .popUp_1 .content_4 ul{display: flex; flex-wrap: wrap;}
.popUpBox .popUp_1 .content_4 .img_viewBox li:not(:nth-child(3n)){margin-right: 2%;}
.popUpBox .popUp_1 .content_4 .img_viewBox li{padding-bottom: 30px; position: relative; border-radius: 5px; width: 32%; margin-bottom: 2%;}
.popUpBox .popUp_1 .content_4 .img_viewBox li img{width: 100%;}
.popUpBox .popUp_1 .content_4 .img_viewBox li .thumbBox{ border: 1px solid #EEEEEE; height: 170px; width: 100%;}
.popUpBox .popUp_1 .content_4 .img_viewBox li .thumbBox .top{cursor: pointer; height: calc(100% - 30px); position: absolute; width: 100%; top: 0; left: 0;}
.popUpBox .popUp_1 .content_4 .img_viewBox li .thumbBox .bottom{ align-items: center; box-sizing: border-box; position: absolute; bottom: 0; width: 100%; left: 0;}
.popUpBox .popUp_1 .content_4 .img_viewBox li .thumbBox .bottom button{border: none; background-color: transparent; margin-left: 5px;}
.popUpBox .popUp_1 .content_4 .img_viewBox li .thumbBox .bottom p{display: block; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; width: 215px;  font-size: 13px; color: #666666; font-weight: 400; line-height: 30px;}
.popUpBox .popUp_1 .content_4 .img_viewBox li .thumbBox .top img{height: 100%; object-fit: contain; width: 100%; border-radius: 5px 5px 0 0;}


.popUpBox .popUp_1 .content_4 .zoomBox{display: none; position: fixed; width: 100%; height: 100%; left: 0; top: 0;}
.popUpBox .popUp_1 .content_4 .zoomBox .bg{ backdrop-filter: blur(10px); position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999;}
.popUpBox .popUp_1 .content_4 .zoomBox .imgBox{z-index: 9999; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 720px; height: 513px;}
.popUpBox .popUp_1 .content_4 .zoomBox .imgBox img{width: 720px; height: 513px;}
.popUpBox .popUp_1 .content_4 .zoomBox.active{display: block;}
/* .popUpBox .popUp_1 .content_4 .zoomBox.active{display: block;} */

.popUpBox .popUp_1 .content_4 .zoomBox{display: none; position: fixed; width: 100%; height: 100%; left: 0; top: 0;}
.popUpBox .popUp_1 .content_4 .zoomBox .bg{background-color: rgba(0,0,0,0.3); backdrop-filter: blur(10px); position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999;}
.popUpBox .popUp_1 .content_4 .zoomBox .imgBox{z-index: 9999; position: absolute; left: 50%; top: 40%; transform: translate(-50%, -50%); width: 720px; height: 513px;}
.popUpBox .popUp_1 .content_4 .zoomBox .imgBox .zoomBoxCloseBtn{border: none; outline: none; background: url('/public/assets/images/modelingCtrl/popUp_thumb_close.png') center / contain no-repeat; position: absolute; width: 20px; height: 20px; right: 10px; top: 10px; z-index: 9999;}

.popUpBox .popUp_1 .content_4 .zoomBox .imgBox img{position: absolute; z-index: 9999; width: 720px; height: 513px;}
.popUpBox .popUp_1 .content_4 .frmAssemblePerformance_slider.slick-initialized.slick-slider,
.popUpBox .popUp_1 .content_4 .slick-slider .slick-track,
.popUpBox .popUp_1 .content_4 .slick-slider .slick-list{height: 555px;}
.popUpBox .popUp_1 .content_4 .zoomBox .imgBox .slick-slide.slick-current.slick-active{position: absolute; z-index: 9999; width: 720px; height: 555px;}

.popUpBox .popUp_1 .content_4 .slick-slide p{display: none; position: absolute; z-index: 9999; color: #fff; width: 100%; bottom: 0;}
.popUpBox .popUp_1 .content_4 .zoomBox .imgBox .slick-slide.slick-current.slick-active p{left: 50%; transform: translateX(-50%); width: 300px; display: block; text-align: center;}
.popUpBox .popUp_1 .content_4 .frmAssemblePerformance_slider .slick-arrow{border: none; outline: none; text-indent: -9999px; position: absolute; background-color: rgba(255,255,255,0.3); width: 50px; height: 50px; border-radius: 100%;}
.popUpBox .popUp_1 .content_4 .frmAssemblePerformance_slider .slick-arrow.slick-prev{left: 0; bottom: -110px;}
.popUpBox .popUp_1 .content_4 .frmAssemblePerformance_slider .slick-arrow.slick-next{right: 0; bottom: -100px;}
.popUpBox .popUp_1 .content_4 .frmAssemblePerformance_slider .slick-arrow:after{content: ''; position: absolute; width: 10px; height: 19px; background-repeat: no-repeat; background-size: contain; background-position: center; left: 50%; top: 50%; transform: translate(-50%, -50%);}
.popUpBox .popUp_1 .content_4 .frmAssemblePerformance_slider .slick-arrow.slick-prev:after{background-image: url('/public/assets/images/modelingCtrl/popUp_slider_left.png');}
.popUpBox .popUp_1 .content_4 .frmAssemblePerformance_slider .slick-arrow.slick-next:after{background-image: url('/public/assets/images/modelingCtrl/popUp_slider_right.png');}
.popUpBox .popUp_1 .content_4 .slick-counter{bottom: -110px; left: 50%; transform: translateX(-50%); font-weight: 300; position: absolute; width: 3.6vw; min-width: 70px; height: 50px;  letter-spacing: -0.25px; color: #fff;}
.popUpBox .popUp_1 .content_4 .slick-counter:after{content: '/'; position: absolute;  top: 50%; transform: translate(-50%, -50%); left: 50%;}
.popUpBox .popUp_1 .content_4 .slick-counter .current{position: absolute; top: 50%; transform: translateY(-50%); left: 0; font-size: 16px;}
.popUpBox .popUp_1 .content_4 .slick-counter .current:before{content: '0';}
.popUpBox .popUp_1 .content_4 .slick-counter .total{position: absolute; top: 50%; transform: translateY(-50%); right: 0; font-size: 16px; opacity: 0.5;}
.popUpBox .popUp_1 .content_4 .slick-counter .total:before{content: '0';}


.popUpBox .popUp_1 .contentBox .content.content_6{padding: 30px 40px !important; height: 690px; overflow-y: auto;}
.popUpBox .popUp_1 .content_6 ul li:not(:last-child){margin-bottom: 15px;}
.popUpBox .popUp_1 .content_6 ul li div{word-break: break-all; padding: 20px 25px; width: 100%; background-color: #F9F9F9; border-radius: 5px;}

/* popUp_1 Fin */

/* popUp_2 */

.popUpBox .popUp_2 .contentBox .content_1,
.popUpBox .popUp_2 .contentBox .content_4,
.popUpBox .popUp_2 .contentBox .content_5,
.popUpBox .popUp_2 .contentBox .content_6{padding: 20px 20px 40px 20px; box-sizing: border-box; height: 690px; overflow-y: auto;}

.popUpBox .popUp_2 .iconBox li:nth-child(1) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu1.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(1).active .icon,
.popUpBox .popUp_2 .iconBox li:nth-child(1):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu1_active.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(2) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu2.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(2).active .icon,
.popUpBox .popUp_2 .iconBox li:nth-child(2):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu2_active.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(3) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu3.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(3).active .icon,
.popUpBox .popUp_2 .iconBox li:nth-child(3):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu3_active.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(4) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu4.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(4).active .icon,
.popUpBox .popUp_2 .iconBox li:nth-child(4):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu4_active.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(5) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu5.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(5).active .icon,
.popUpBox .popUp_2 .iconBox li:nth-child(5):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu5_active.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(6) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu6.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(6).active .icon,
.popUpBox .popUp_2 .iconBox li:nth-child(6):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp2_topMenu6_active.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(7) .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu6.png');}
.popUpBox .popUp_2 .iconBox li:nth-child(7).active .icon,
.popUpBox .popUp_2 .iconBox li:nth-child(7):hover .icon{background-image: url('/public/assets/images/modelingCtrl/popUp1_topMenu6_active.png');}


.popUpBox .popUp_2 .dateCheckBox{display: flex; flex-wrap: wrap;}
.popUpBox .popUp_2 .dateCheckBox li{width: 31%; margin-bottom: 40px;}
.popUpBox .popUp_2 .dateCheckBox li:not(:nth-child(3n)){margin-right: 3.5%;}
.popUpBox .popUp_2 .dateCheckBox label{font-size: 14px; font-weight: 500; color: #444444; display: block; margin-bottom: 10px;} 
.popUpBox .popUp_2 .dateCheckBox input{box-sizing: border-box; cursor: default; outline: none; font-size: 14px; padding: 10px 15px; border-radius: 5px; border: 1px solid #EEEEEE; color: #999999; font-weight: 400; width: 100%;}


.popUpBox .popUp_2 .dateCheckBox .inputBox div{position: relative;}
.popUpBox .popUp_2 .dateCheckBox .inputBox div span{font-size: 14px; color: #CCCCCC; font-weight: 400; position: absolute; top: 50%; right: 12px; transform: translateY(-50%);}
/* .popUpBox .popUp_2 .file_historyBox{display: flex; flex-direction: column-reverse; width: 100%;}
.popUpBox .popUp_2 .file_historyBox .file_historyList:not(:first-child){margin-bottom: 20px;}
.popUpBox .popUp_2 .file_historyBox .file_historyList > div{box-sizing: border-box; box-shadow: rgb(0 0 0 / 0.05) 1px 1px 10px 1px;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .commentBox{position: relative; padding: 25px 25px 25px 50px; box-sizing: border-box; border-top: 1px solid #EEEEEE;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .commentBox .comment_icon{position: absolute; left: 25px; top: 25px;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox{padding: 20px 25px; box-sizing: border-box; display: flex; justify-content: space-between;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p{color: #555555; font-size: 16px; padding-left: 30px; position: relative;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p span{position: absolute; left: 0; top: 5px; width: 12px; height: 12px; border-radius: 100%;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.red span{background-color: #FF7474;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.blue span{background-color: #256DCB;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox .file_history_date{font-size: 14px; font-weight: 400; color: #CCCCCC; line-height: 24px;} */
.popUpBox .popUp_2 .file_historyBox{padding: 40px 45px; box-sizing: border-box; display: flex; flex-direction: column-reverse; width: 100%;}
.popUpBox .popUp_2 .file_historyBox .file_historyList:not(:first-child){margin-bottom: 20px;}
.popUpBox .popUp_2 .file_historyBox .file_historyList > div{box-sizing: border-box; box-shadow: rgb(0 0 0 / 0.05) 1px 1px 10px 1px;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .commentBox{position: relative; padding: 25px 25px 25px 50px; box-sizing: border-box; border-top: 1px solid #EEEEEE;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .commentBox p{font-size: 15px; font-weight: 400; line-height: 26px; color: #666666;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .commentBox p span{display: block;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .commentBox .comment_icon{height: 26px; position: absolute; left: 25px; top: 25px;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .commentBox .comment_icon img{position: absolute; top: 50%; transform: translateY(-50%);}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox{padding: 20px 25px; box-sizing: border-box; display: flex; justify-content: space-between;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p{line-height: 26px; color: #555555; font-size: 16px; padding-left: 30px; position: relative;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.lock,
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.lock a{cursor: default;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.unlock,
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.unlock a{cursor: pointer;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p .ball{position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 12px; height: 12px; border-radius: 100%;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p .ball:before{content: ''; display: block; width: 1px; height: 44px; position: absolute; left: 50%; transform: translateX(-50%); top: -44px;}
.popUpBox .popUp_2 .file_historyBox .file_historyList:last-child .file_titleBox p .ball:before{display: none;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.red .ball:before{background-color: #FF7474;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.blue .ball:before{background-color: #256DCB;}
.popUpBox .popUp_2 .file_historyBox .file_historyList.active .file_titleBox p.red .ball:before{background-color: #256DCB;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p a{font-size: 16px; color: #555555; font-weight: 400; text-decoration: none;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p span:last-of-type{margin-left: 30px; color: #AAAAAA; font-size: 12px; font-weight: 500; position: relative; padding-left: 20px;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p .lock:before{content: ''; width: 14px; height: 16px; left: 0; top: 50%; transform: translateY(-50%); background: url('/public/assets/images/modelingCtrl/lock.png') center / contain no-repeat; position: absolute; }
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p .unlock:before{content: ''; width: 18px; height: 16px; left: 0; top: 50%; transform: translateY(-50%); background: url('/public/assets/images/modelingCtrl/lock_open.png') center / contain no-repeat; position: absolute; }
.popUpBox .popUp_2 .file_historyBox .file_historyList.active .file_titleBox p .lock{color: transparent;}
.popUpBox .popUp_2 .file_historyBox .file_historyList.active .file_titleBox p .lock:before{width: 18px; background-image: url('/public/assets/images/modelingCtrl/lock_open.png');}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.red .ball{background-color: #FF7474;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox p.blue .ball{background-color: #256DCB;}
.popUpBox .popUp_2 .file_historyBox .file_historyList.active .file_titleBox p.red .ball{background-color: #256DCB;}
.popUpBox .popUp_2 .file_historyBox .file_historyList .file_titleBox .file_history_date{font-size: 14px; font-weight: 400; color: #CCCCCC; line-height: 24px;}


.popUpBox .popUp_2 .dataBox{padding: 40px 45px; box-sizing: border-box; height: 610px; overflow-y: auto;}
.popUpBox .popUp_2 .dataBox .dataList .dataList_title input[type='file']{display: none;}
.popUpBox .popUp_2 .dataBox .dataList:not(:last-child){margin-bottom: 20px;}
.popUpBox .popUp_2 .dataBox .dataList .dataList_title{border-radius: 10px; border: 1px solid #EEEEEE; padding: 25px 30px; box-sizing: border-box; cursor: pointer; display: flex;}
.popUpBox .popUp_2 .dataBox .dataList .dataList_title.active{background-color: #F9F9F9;}
.popUpBox .popUp_2 .dataBox .dataList p{position: relative; padding-left: 30px; font-size: 16px; line-height: 30px; color: #555555;}
.popUpBox .popUp_2 .dataBox .dataList p span{width: 12px; height: 12px; border-radius: 100%; position: absolute; left: 0; top: 50%; transform: translateY(-50%);}
.popUpBox .popUp_2 .dataBox .dataList p.blue span{background-color: #256DCB;}
.popUpBox .popUp_2 .dataBox .dataList p.red span{background-color: #FF7474;}
.popUpBox .popUp_2 .dataBox .dataList p.gray{color: #CCCCCC;}
.popUpBox .popUp_2 .dataBox .dataList p.gray span{background-color: #CCCCCC;}
.popUpBox .popUp_2 .dataBox .dataList .dataList_title a{text-decoration: none; line-height: 30px; width: 60px; background-color: #DB1A1A; border: none; text-align: center; border-radius: 5px; color: #fff; font-size: 14px; font-weight: 400;}

.popUpBox .popUp_2 .dataBox .dataList .commentReadBox{margin-left: 10px; position: relative;}
.popUpBox .popUp_2 .dataBox .dataList .commentReadBox div{height: 120px; overflow-y: auto; z-index: 999; box-shadow: rgb(0 0 0 / 0.1) 1px 1px 5px 1px; font-size: 14px; line-height: 22px; font-weight: 400; color: #666666; width: 210px; padding: 12px 15px; border-radius: 3px; box-sizing: border-box; background-color: #fff;  display: none; left: 20px; position: absolute;}
.popUpBox .popUp_2 .dataBox .dataList:not(:last-of-type) .commentReadBox div{top: 0;}
.popUpBox .popUp_2 .dataBox .dataList:last-of-type .commentReadBox div{left: 17px; bottom: 0;}
.popUpBox .popUp_2 .dataBox .dataList:first-of-type .commentReadBox div{top: 0; left: 17px;}
.popUpBox .popUp_2 .dataBox .dataList .commentReadBox span{position: absolute; top: 6px; display: block; width: 16px; height: 16px; background: url('/public/assets/images/modelingCtrl/report_comment.png') center / contain no-repeat; }
.popUpBox .popUp_2 .dataBox .dataList .commentReadBox:hover span{background-image: url('/public/assets/images/modelingCtrl/report_comment_hover.png');}
.popUpBox .popUp_2 .dataBox .dataList .commentReadBox:hover span + div,
.popUpBox .popUp_2 .dataBox .dataList .commentReadBox span:hover + div{display: block;}
.popUpBox .popUp_2 .dataBox .dataList .commentReadBox div:hover{display: block;}
.popUpBox .popUp_2 .dataBox .dataList .commentReadBox:hover > div{display: block;}

.popUpBox .popUp_2 .dataBox .dataList .uploadImgArea li{margin-bottom: 1.5%; border-radius: 5px; height: 160px; background-color: #F9F9F9; border: 1px solid #EEEEEE; width: 32.33%;}
.popUpBox .popUp_2 .dataBox .dataList .uploadImgArea li:not(:nth-child(3n)){margin-right: 1.5%;}
.popUpBox .popUp_2 .dataBox .dataList .uploadImgArea li > div{position: relative;}
.popUpBox .popUp_2 .dataBox .dataList .uploadImgArea li > div div{border-radius: 5px;}
.popUpBox .popUp_2 .dataBox .dataList .uploadImgArea li > div div img{border-radius: 5px; width: 100%; height: 160px; object-fit: cover;}
.popUpBox .popUp_2 .dataBox .dataList .uploadImgArea li > div button{background-color: transparent; border: none; outline: none; line-height: 0; font-size: 0; padding: 0; position: absolute; right: 12px; top: 12px;}

.popUpBox .popUp_2 .data_imgBox{margin-top: 35px; display: none; flex-wrap: wrap;}
.popUpBox .popUp_2 .data_imgBox.active{display: flex;}
.popUpBox .popUp_2 .data_imgBox li:not(:nth-child(3n)){margin-right: 2%;}
.popUpBox .popUp_2 .data_imgBox li{width: 32%; margin-bottom: 2%;}
.popUpBox .popUp_2 .data_imgBox li img{width: 100%;}

/* 아코디언 */
.popUpBox .popUp_2 .dataBox .dataList .catalogDown,
.popUpBox .popUp_2 .dataBox .dataList .uploadImgArea{display: none;}
.popUpBox .popUp_2 .dataBox .dataList.active .dataList_title{background-color: #F9F9F9;}
.popUpBox .popUp_2 .dataBox .dataList.active .catalogDown{display: flex;}
.popUpBox .popUp_2 .dataBox .dataList.active .uploadImgArea{display: flex; flex-wrap: wrap; }
/* 아코디언 */

.popUpBox .popUp_2 .contentBox .content_2{height: 690px; overflow-y: auto;}

.popUpBox .popUp_2 .content_3 .catalogDown{margin-top: 10px; margin-bottom: 15px;}

.popUpBox .popUp_2 .content_5 .videoWrap li:not(:last-child){margin-bottom: 5%;}

.popUpBox .popUp_2 .content_7{padding: 30px 40px; box-sizing: border-box; height: 690px; overflow-y: auto;}
.popUpBox .popUp_2 .content_7 ul li:not(:last-child){margin-bottom: 15px;}
.popUpBox .popUp_2 .content_7 ul li div{word-break: break-all; padding: 20px 25px; width: 100%; background-color: #F9F9F9; border-radius: 5px;}
/* popUp_2 Fin */



.popUpBox .popUp_3 .titleBox{padding: 40px 40px 12px 40px; width: 100%; border-bottom: 1px solid #E4EAEF;}
.popUpBox .popUp_3 .titleBox span{font-size: 14px; color: #256DCB; padding: 13px 12px; position: relative;}
.popUpBox .popUp_3 .titleBox span.active:after{content: ''; position: absolute; display: block; width: 100%; height: 2px; background-color: #256DCB; bottom: -3px; left: 0;}
.popUpBox .popUp_3 fieldset{padding: 40px 60px; box-sizing: border-box;}
.popUpBox .popUp_3 fieldset legend{display: none;}
.popUpBox .popUp_3 fieldset ul{display: flex; flex-wrap: wrap;}
.popUpBox .popUp_3 fieldset ul li{width: 48%; margin-bottom: 45px;}
.popUpBox .popUp_3 fieldset ul li:not(:nth-child(2n)){margin-right: 4%;}


.popUpBox .popUp_3 label{font-size: 16px; font-weight: 700; color: #444444; display: block; margin-bottom: 10px;} 
.popUpBox .popUp_3 input{width: 100%; outline: none; border: none; border-bottom: 1px solid #EEEEEE; padding: 10px 0; font-size: 14px; font-weight: 400;}
/* .popUpBox .popUp_3 input:focus,
.popUpBox .popUp_3 input.active{border-bottom: 1px solid #666666;}
.popUpBox .popUp_3 textarea.active,
.popUpBox .popUp_3 textarea:focus{border: 1px solid #666666;} */
.popUpBox .popUp_3 input::placeholder{color: #CCCCCC; font-size: 14px; font-weight: 400;}
.popUpBox .popUp_3 textarea{border-radius: 5px; border: 1px solid #EEEEEE; outline: none; width: 100%; resize: none; height: 200px;}
.popUpBox .popUp_3 .contactUs_submitBtn{line-height: 60px; width: 100%; border: none; background-color: #256DCB; color: #fff; font-size: 500; font-size: 16px;}
/* popUp_3 Fin */


.popUpBox .popUp_4 .contentBox{padding: 30px 40px; box-sizing: border-box;}
.popUpBox .popUp_4 .titleBox{padding: 40px 40px 12px 40px; width: 100%; border-bottom: 1px solid #E4EAEF;}
.popUpBox .popUp_4 .titleBox span{font-size: 14px; color: #256DCB; padding: 13px 12px; position: relative;}
.popUpBox .popUp_4 .titleBox span.active:after{content: ''; position: absolute; display: block; width: 100%; height: 2px; background-color: #256DCB; bottom: -3px; left: 0;}
.popUpBox .popUp_4 .ballastingBox{margin-bottom: 60px;}
.popUpBox .popUp_4 .ballastingBox > span,
.popUpBox .popUp_4 .deballastingBox > span{margin-bottom: 10px; font-size: 14px; font-weight: 400; color: #666666; text-align: center; width: 125px; border: 1px solid #DDDDDD; border-radius: 5px; display: block; line-height: 30px;}
.popUpBox .popUp_4 .listBox{display: flex;}
.popUpBox .popUp_4 .listBox > div{align-content: center; padding: 40px 0; width: 32.33%; border-radius: 5px; background-color: #F9F9F9; box-sizing: border-box; border: 1px solid #EEEEEE;}
.popUpBox .popUp_4 .listBox > div:not(:nth-child(3n)){margin-right: 1.5%;}
.popUpBox .popUp_4 .listBox > div > div > span{margin-bottom: 5px; display: block; font-size: 48px; line-height: 56px; font-weight: 200; text-align: center;}
.popUpBox .popUp_4 .listBox > div > div > span.gray{color: #CCCCCC;}
.popUpBox .popUp_4 .listBox > div > div > span.red{color: #F37373;}
.popUpBox .popUp_4 .listBox > div > div > span.blue{color: #035EAF;}
.popUpBox .popUp_4 .listBox > div > div > p strong{font-size: 15px; font-weight: 600;}
.popUpBox .popUp_4 .listBox > div > div > p{font-weight: 400; text-align: center; color: #555555;}
/* popUp_4 Fin */

.catalogDown.none,
.catalogDown.none input{cursor: default;}


.none_content{background-color: #F9F9F9; border-radius: 10px; width: 100%; padding: 255px 0;}
.none_content p{margin-bottom: 20px; text-align: center; font-size: 16px; line-height: 26px; color: #666666;}
.none_content p span{display: block;}
.none_content button{display: block; margin: 0 auto; border: none; outline: none; background-color: #002E57; border-radius: 21px; font-size: 14px; font-weight: 400; text-align: center; color: #fff; padding: 10px 35px;}

.none_content_admin{background-color: #f9f9f9; border-radius: 10px; width: 100%; padding: 80px 0;}
.none_content_admin p{text-align: center; font-size: 16px; line-height: 26px; color: #666666;}
.none_content_admin p span{display: block;}






/* 추가된팝업 */
.popUpOpen_btnBox .mpContent_btn.active{background-color: #1B6ADD;}
.popUpOpen_btnBox .mpContent_btn.active span{background-image: url('/public/assets/images/modelingCtrl/mpContent_popUpBtn_active.png');}
.popUpOpen_btnBox .mpContent_btn span{background-image: url('/public/assets/images/modelingCtrl/mpContent_popUpBtn.png');}
.popUpOpen_btnBox .companyInformation_btn{position: relative;}
.popUpOpen_btnBox .companyInformation_btn.active{background-color: #1B6ADD;}
.popUpOpen_btnBox .companyInformation_btn.active span{background-image: url('/public/assets/images/modelingCtrl/companyInformationPopUpBtn_active.png');}
.popUpOpen_btnBox .companyInformation_btn span{background-image: url('/public/assets/images/modelingCtrl/companyInformationPopUpBtn.png');}


.popUpBox .mpContent_popUp .iconBox li.iconList_1 .icon{background-image: url('/public/assets/images/modelingCtrl/marineNews.png');}
.popUpBox .mpContent_popUp .iconBox li.iconList_1.active .icon,
.popUpBox .mpContent_popUp .iconBox li.iconList_1:hover .icon{background-image: url('/public/assets/images/modelingCtrl/marineNews_active.png');}
.popUpBox .mpContent_popUp .iconBox li.iconList_2 .icon{background-image: url('/public/assets/images/modelingCtrl/catalogue.png');}
.popUpBox .mpContent_popUp .iconBox li.iconList_2.active .icon,
.popUpBox .mpContent_popUp .iconBox li.iconList_2:hover .icon{background-image: url('/public/assets/images/modelingCtrl/catalogue_active.png');}
.popUpBox .mpContent_popUp .iconBox li.iconList_3 .icon{background-image: url('/public/assets/images/modelingCtrl/video.png');}
.popUpBox .mpContent_popUp .iconBox li.iconList_3.active .icon,
.popUpBox .mpContent_popUp .iconBox li.iconList_3:hover .icon{background-image: url('/public/assets/images/modelingCtrl/video_active.png');}
.popUpBox .mpContent_popUp .iconBox li.iconList_4 .icon{background-image: url('/public/assets/images/modelingCtrl/techDocument.png');}
.popUpBox .mpContent_popUp .iconBox li.iconList_4.active .icon,
.popUpBox .mpContent_popUp .iconBox li.iconList_4:hover .icon{background-image: url('/public/assets/images/modelingCtrl/techDocument_active.png');}
.popUpBox .mpContent_popUp .iconBox li.iconList_5 .icon{background-image: url('/public/assets/images/modelingCtrl/forums.png');}
.popUpBox .mpContent_popUp .iconBox li.iconList_5.active .icon,
.popUpBox .mpContent_popUp .iconBox li.iconList_5:hover .icon{background-image: url('/public/assets/images/modelingCtrl/forums_active.png');}

.popUpBox .mpContent_popUp .contentBox .content{overflow-y: auto; height: 690px;}
.popUpBox .mpContent_popUp .contentBox .content_1,
.popUpBox .mpContent_popUp .contentBox .content_3{padding: 30px 55px;}
.popUpBox .mpContent_popUp .contentBox .content_2,
.popUpBox .mpContent_popUp .contentBox .content_4{padding: 30px 20px;}
.popUpBox .mpContent_popUp .contentBox .content_5{padding: 20px;}

/* content 리스트 전체 공통 */
.listType_all{width: 100%;}
.listType_all li{line-height: 0; overflow: hidden; border-radius: 5px; position: relative;}
.listType_all li > a{border-radius: 5px; position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 1;}
.listType_all li .scaleImgBox img{line-height: 0; width: 100%;}
.listType_all li > span{z-index: 9; position: absolute; right: 8px; top: 8px; background-color: #fff; color: #1B6ADD; font-size: 12px; font-weight: 700; line-height: 24px; border-radius: 3px; padding: 0 15px; display: block; text-align: center;}
/* content 리스트 전체 공통 Fin */

/* 리스트 이미지 hover시 커지는 효과 */
.scaleImgBox{line-height: 0;}
.scaleImgBox{position: relative; cursor: pointer; width: 100%; height: 100%; overflow: hidden;}
.scaleImgBox img{object-fit: cover; width: 100%; height: 100%; transition: all 0.2s linear;}
.listType_all li:hover .scaleImgBox img{transform: scale(1.1);}
.listType_all li .scaleImgBox a{position: absolute; width: 100%; height: 100%; left: 0; top: 0; z-index: 99;}
/* 리스트 이미지 hover시 커지는 효과 */

/* 리스트 3칸 */
.listType_3equalParts{display: flex; flex-wrap: wrap;}
.listType_3equalParts li{width: 31.33%; margin-bottom: 3%;}
.listType_3equalParts li:not(:nth-child(3n)){margin-right: 3%;}
/* 리스트 3칸 Fin */

/* 리스트 2칸 */
.listType_2equalParts{display: flex; flex-wrap: wrap;}
.listType_2equalParts li{width: 49%; margin-bottom: 2%;}
.listType_2equalParts li:not(:nth-child(2n)){margin-right: 2%;}
/* 리스트 2칸 Fin */

.contentSaveBox_circle{position: absolute; width: 100%; height: 36px;}
.contentSaveBox_circle button.contentSaveBtn{z-index: 9; position: absolute; background-color: #fff; padding: 10px; border-radius: 100%; border: none; top: 0;}
.contentSaveBox_circle button.contentSaveBtn span{background: url('/public/assets/images/modelingCtrl/content_save_gray.png') center / contain no-repeat; width: 19px; height: 18px; display: block;}
.contentSaveBox_circle button.contentSaveBtn:hover span{background-image: url('/public/assets/images/modelingCtrl/content_save_hover.png');}
.contentSaveBox_circle button.contentSaveBtn:hover + span{display: block;}
.contentSaveBox_circle > span{display: none; position: absolute; z-index: 99; right: 55px; top: 50%; transform: translateY(-50%); padding: 7px 12px; border-radius: 5px; background-color: #222222; color: #fff; font-size: 12px; font-weight: 400; line-height: 12px;}
.contentSaveBox_circle > span:after{content: ''; position: absolute; background: url('/public/assets/images/modelingCtrl/triangle.png') center / contain no-repeat; width: 13px; height: 9px; top: 50%; transform: translateY(-50%);}
.contentSaveBox_circle > span:after{right: -9px;}

.listType_b .contentSaveBox_circle > span{right: 55px;}
.listType_c .contentSaveBox_circle > span{right: 60px;}

/* content_2, content_4 리스트 공통 */
.listType_b li .contentSaveBox_circle button.contentSaveBtn{right: 15px;}
.listType_b li{padding-bottom: 125px; border: 1px solid #EEEEEE; border-radius: 5px; box-sizing: border-box;}
.listType_b li .bottomBox .contentSaveBox_circle{position: absolute; left: 0; top: -20px;}
.listType_b .bottomBox{padding: 20px; box-sizing: border-box; position: absolute; bottom: 0; left: 0; width: 100%; height: 125px;}
.listType_b .bottomBox p{cursor: pointer; width: 100%; font-size: 15px; color: #444444; font-weight: 700; line-height: 22px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
.listType_b .bottomBox > a{display: block; text-decoration: none; width: 100%; font-size: 15px; color: #444444; font-weight: 700; line-height: 22px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
.listType_b .bottomBox .hashTagBox{display: flex; margin-bottom: 25px;}
.listType_b .bottomBox .hashTagBox a{display: block; width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; text-decoration: none; font-size: 14px; font-weight: 300; color: #1B6ADD; line-height: 20px;}
.listType_b .bottomBox .hashTagBox a:not(:first-child){margin-left: 10px;}
.listType_b .bottomBox .dateBox{display: flex; align-items: center; justify-content: space-between;}
.listType_b .bottomBox .dateBox > div{display: flex; align-items: center;}
.listType_b .bottomBox .dateBox > div span{font-size: 12px; line-height: 14px; color: #666666;}
.listType_b .bottomBox .dateBox .icon{position: relative; margin-right: 10px; background-color: #F5F5F5; width: 26px; height: 26px; border-radius: 100%; display: block;}
.listType_b .bottomBox .dateBox .icon img{position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); max-width: 16px; display: block;}
.listType_b .bottomBox .dateBox .date{font-size: 12px; line-height: 14px; color: #999999; font-weight: 400;}
.listType_b li .scaleImgBox{height: 220px;}
.listType_b li .scaleImgBox img{width: 100%; object-fit: cover; height: 220px;}
/* content_2, content_4 리스트 공통 Fin */

/* content_3, content_5 리스트 공통 */
.listType_quartered.listType_c li{margin-bottom: 40px;}
.listType_c li .contentSaveBox_circle button.contentSaveBtn{right: 20px;}
.listType_c li{padding-bottom: 65px; border-radius: 5px; box-sizing: border-box;}
.listType_c li .bottomBox .contentSaveBox_circle{position: absolute; top: -20px;}
.listType_c .bottomBox{position: absolute; bottom: 0; left: 0; width: 100%; height: 65px; padding-top: 20px; box-sizing: border-box;}
.listType_c .bottomBox p{width: 100%; font-size: 15px; color: #444444; font-weight: 700; line-height: 22px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}
.listType_c .bottomBox p span{font-size: 13px; font-weight: 500; color: #999999; line-height: 20px; display: block;}
.listType_c li .scaleImgBox{height: 300px;}
.listType_c li .scaleImgBox img{width: 100%; object-fit: cover; height: 300px;}
/* content_3, content_5 리스트 공통 Fin */



.forums table thead tr th .favorite label:before{background-image: url('/public/assets/images/modelingCtrl/star_yellowSolid.png');}
.forums table tbody tr td .favorite label:before{background-image: url('/public/assets/images/modelingCtrl/star_graySolid.png');}

/* .forums table thead tr th .check label:before{background-image: url('/public/assets/images/modelingCtrl/post_checkBox.png');}
.forums table tbody tr td .check label:before{background-image: url('/public/assets/images/modelingCtrl/post_checkBox.png');} */

/* forums - postList */
.forums .favorite input[type='checkbox']{display: none;}
.forums .favorite label{cursor: pointer; position: relative; padding-left: 35px;}
.forums .favorite label:before{content: ''; background-repeat: no-repeat; background-position: center; background-size: contain; width: 15px; height: 15px; left: 0; top: 50%; transform: translateY(-50%); position: absolute;}
.forums .favorite input[type='checkbox']:checked + label:before{background-image: url('/public/assets/images/modelingCtrl/star.png');}
.forums table thead tr th .favorite label:before{background-image: url('/public/assets/images/modelingCtrl/star_yellowSolid.png');}
.forums table tbody tr td .favorite label:before{background-image: url('/public/assets/images/modelingCtrl/star_graySolid.png');}

.forums .check input[type='checkbox']{display: none;}
.forums .check label{cursor: pointer; position: relative; padding-left: 35px;}
.forums .check label:before{content: ''; background-repeat: no-repeat; background-position: center; background-size: contain; width: 16px; height: 16px; left: 0; top: 50%; transform: translateY(-50%); position: absolute;}
/* .forums .check input[type='checkbox']:checked + label:before{background-image: url('/public/assets/images/modelingCtrl/post_checkBox_checked.png');} */
/* .forums table thead tr th .check label:before{background-image: url('/public/assets/images/modelingCtrl/post_checkBox.png');}
.forums table tbody tr td .check label:before{background-image: url('/public/assets/images/modelingCtrl/post_checkBox.png');} */


.popUpBox .mpContent_popUp .contentBox .content_5 > div{overflow-x: scroll;}
.forums table{width: 100%; margin-bottom: 30px;}
.forums table thead tr th{height: 52px; vertical-align: middle; font-size: 15px; font-weight: 400; color: #999999; text-align: left; background-color: #F9F9F9;}
.forums table thead tr th.post{color: #777777;}
.forums table thead tr th:nth-child(1){padding-left: 20px; box-sizing: border-box;}
.forums table thead tr th:nth-child(1),
.forums table tbody tr td:nth-child(1){width: 55px;}
.forums table thead tr th:nth-child(2),
.forums table tbody tr td:nth-child(2){width: 465px;}
.forums table thead tr th:nth-child(3),
.forums table tbody tr td:nth-child(3){width: 110px;}
.forums table thead tr th:nth-child(4),
.forums table tbody tr td:nth-child(4){width: 120px;}
.forums table thead tr th:nth-child(4),
.forums table tbody tr td:nth-child(4){text-align: center;}
.forums table tbody tr td:nth-child(1) span,
.forums table tbody tr td:nth-child(2) span,
.forums table tbody tr td:nth-child(3) span,
.forums table tbody tr td:nth-child(4) span,
.forums table tbody tr td:nth-child(5) span{overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display: block;}
.forums table tbody tr td:nth-child(2) span{width: 465px; cursor: pointer;}
.forums table tbody tr td:nth-child(3) span{width: 110px; padding: 0 10px; box-sizing: border-box;}
.forums table tbody tr td:nth-child(4) span{width: 120px; padding: 0 10px; box-sizing: border-box;}
.forums table tbody tr td:nth-child(5) > div > div > span{width: 130px;}
.forums table tbody tr:hover td:nth-child(2) span{text-decoration: underline;}
.forums table tbody tr td{height: 52px; vertical-align: middle; font-size: 14px; font-weight: 400; color: #777777;}
.forums table tbody tr td:nth-child(1){padding-left: 20px; box-sizing: border-box;}
.forums table tbody tr td:nth-child(5) > div{display: flex; align-items: center;}
/* .forums table tbody tr td:nth-child(5) > div > span{display: block; background: url('/public/assets/images/modelingCtrl/author_profile.png') center / cover no-repeat; width: 36px; height: 36px; border-radius: 5px;} */
.forums table tbody tr td:nth-child(5) > div > div{margin-left: 12px;}
.forums table tbody tr td:nth-child(5) > div > div > span:nth-child(1){display: block; font-size: 14px; font-weight: 500; color: #666666; margin-bottom: 3px;}
.forums table tbody tr td:nth-child(5) > div > div > span:nth-child(2){font-size: 12px; font-weight: 400; color: #999999;}

.forums table tbody tr td.author div.profileThumbBox span{width: auto;}
.forums table tbody tr td.author > div{position: relative; cursor: pointer;}
.forums table tbody tr td.author .closeBtn{position: absolute; top: 15px; right: 15px; z-index: 99; width: 40px; height: 40px; border-radius: 100%; border: none;}
.forums table tbody tr td.author .closeBtn i{font-size: 14px; animation: rotate_Btn_return 0.5s backwards;}
.forums table tbody tr td.author .closeBtn:hover{background-color: #222222;}
.forums table tbody tr td.author .closeBtn:hover i{animation: rotate_Btn 0.5s backwards; color: #63FAFF;}
.forums table tbody tr td.author .profileThumbBox{background-color: #fff; z-index: 99; box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.15); border-radius: 5px; height: 200px; left: -525px; top: 0; width: 500px; position: absolute; display: none;}
.forums table tbody tr td.author .profileThumbBox.active{display: block;}
.forums table tbody tr td.author .profileThumbBox .profileImgBox{border-radius: 5px 5px 0 0; z-index: 9; position: absolute; top: 0; left: 0; width: 100%; background-color: #E2E2E2; height: 75px;}
.forums table tbody tr td.author .profileThumbBox .profileImgBox > div{background-color: #fff; padding: 10px; border-radius: 10px; position: absolute; top: 35px; left: 30px;}
.forums table tbody tr td.author .profileThumbBox .profileBox{padding: 95px 0 0 195px; box-sizing: border-box;}
.forums table tbody tr td.author .profileThumbBox .profileBox > span{font-size: 20px; font-weight: 500; color: #444444; margin-bottom: 10px; display: block; text-align: left;}
.forums table tbody tr td.author .profileThumbBox .profileBox > div{display: flex;}
.forums table tbody tr td.author .profileThumbBox .profileBox > div > span:nth-child(1){position: relative; padding-right: 15px; margin-right: 15px;}
.forums table tbody tr td.author .profileThumbBox .profileBox > div > span:nth-child(1):after{content: ''; display: block; width: 1px; height: 10px; background-color: #DDDDDD; position: absolute; right: 0; top: 50%; transform: translateY(-50%);}
.forums table tbody tr td.author .profileThumbBox .profileBox > div > span{font-size: 14px; font-weight: 300; color: #999999;}
.forums table tbody tr td.author .profileThumbBox .profileBox > div > span span{margin-left: 5px; color: #1B6ADD; font-size: 14px; font-weight: 400;}


/* .forums_postList.posts table thead tr th:nth-child(1){width: 675px;}
.forums_postList.posts table thead tr th:nth-child(2){width: 120px;}
.forums_postList.posts table thead tr th:nth-child(3){width: 120px;}
.forums_postList.posts table thead tr th:nth-child(4){width: 135px; padding-left: 10px; box-sizing: border-box;}
.forums_postList.posts table tbody tr td:nth-child(1) span{width: 670px; cursor: pointer;}
.forums_postList.posts table tbody tr td:nth-child(2) span{width: 120px; padding: 0 10px; box-sizing: border-box;}
.forums_postList.posts table tbody tr td:nth-child(3) span{width: 120px; padding: 0 10px; box-sizing: border-box;}
.forums_postList.posts table tbody tr td:nth-child(4) span{width: 100px; color: #999999; padding-left: 10px; box-sizing: border-box;}
.forums_postList.posts table thead tr th:nth-child(2),
.forums_postList.posts table thead tr th:nth-child(3),
.forums_postList.posts table tbody tr td:nth-child(2),
.forums_postList.posts table tbody tr td:nth-child(3){text-align: center;}
.forums_postList.posts table tbody tr:hover td:nth-child(1) span{text-decoration: underline;} */

table thead tr th .sortBox{position: relative;}
table thead tr th .sortBox .sort {color: #CCCCCC; padding-right: 10px; cursor:pointer; display:inline-block;}
table thead tr th .sortBox .sort::before {content:"\f0de"; font-weight: 900; font-family: "Font Awesome 6 Pro" !important; position:absolute; right:0; top:50%; transform:translateY(-50%); margin-top:-0.5px;}
table thead tr th .sortBox .sort::after {content:"\f0dd"; font-weight: 900; font-family: "Font Awesome 6 Pro" !important; position:absolute; right:0; top:50%; transform:translateY(-50%); margin-top:0.5px;}
table thead tr th .sortBox .sort::before,
table thead tr th .sortBox span.sort::after {font-size: 10px;}
table thead tr th .sortBox .sort.desc::before {color:#CCCCCC;}
table thead tr th .sortBox .sort.desc::after {color:#999999;}
table thead tr th .sortBox .sort.asc::before {color:#999999;}
table thead tr th .sortBox .sort.asc::after {color:#CCCCCC;}

@keyframes rotate_Btn {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(90deg);
    }
  }
  
  @keyframes rotate_Btn_return {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(-90deg);
    }
  }
/* forums - postList Fin */


.product_performance_sliderBG{position: fixed; width: 100%; z-index: 999; height: 100%; left: 0; top: 0; background-color: rgba(0,0,0,0.5);}
.product_performance_sliderWrap{z-index: 9999; width: 720px; height: 513px; position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);}
.product_performance_sliderWrap .product_performance_closeBtn{border: none; outline: none; background: url('/public/assets/images/modelingCtrl/popUp_thumb_close.png') center / contain no-repeat; position: absolute; width: 20px; height: 20px; right: 10px; top: 10px; z-index: 9999;}
.page_carousel{width: 720px; height: 513px; position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);}
.page_carousel img{object-fit: cover; width: 720px; height: 513px;}

.slick-initialized .slick-slide,
.height_box{height: 513px;}

.product_performance_pageCount{position: absolute; bottom: -30px; left: 50%; transform: translateX(-50%); margin-top: 20px; display: flex; justify-content: center;}
.product_performance_pageCount span{font-size: 16px; color: #fff}
.product_performance_pageCount span:nth-child(2){margin: 0 10px;}
.product_performance_pageCount .total{opacity: 0.5;}


.slick-prev, .slick-next{width: 30px !important; height: 30px !important;}
.slick-prev:before,
.slick-next:before{font-size: 30px !important;}


.slick-prev:before,
.slick-next:before{font-size: 30px !important;}

/* 업체정보 팝업 */
.popUpBox .companyInformation_popUp .titleBox{padding: 40px 40px 12px 40px; width: 100%; border-bottom: 1px solid #E4EAEF;}
.popUpBox .companyInformation_popUp .titleBox span{font-size: 14px; color: #256DCB; padding: 13px 12px; position: relative;}
.popUpBox .companyInformation_popUp .titleBox span.active:after{content: ''; position: absolute; display: block; width: 100%; height: 2px; background-color: #256DCB; bottom: -3px; left: 0;}
.popUpBox .companyInformation_popUp .contentBox{padding: 30px 40px; box-sizing: border-box;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(1){display: flex;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(1){position: relative; width: 260px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(1) img{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); max-width: 103px; width: 100%;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(2){padding: 20px 30px; box-sizing: border-box; width: calc(100% - 260px);}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(2) > span{font-size: 16px; font-weight: 600; line-height: 19px; color: #333333; display: block; margin-bottom: 20px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(2) > div:not(:last-child){margin-bottom: 15px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(2) > div > span{font-size: 15px; line-height: 18px; color: #666666; font-weight: 400;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(2) > div > span:nth-child(1){width: 65px; display: inline-block;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(2){margin-bottom: 20px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(2) iframe{width: 100%; height: 250px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div{display: flex;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div > span{display: inline-block; width: 80px; font-size: 15px; font-weight: 400; line-height: 18px; color: #444444;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div:not(:last-child){margin-bottom: 15px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div > div > span{font-size: 15px; font-weight: 300; color: #666666; line-height: 18px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div:nth-child(1) > div > span{padding-left: 20px; position: relative;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div:nth-child(1) > div > span:not(:first-child){margin-left: 20px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div:nth-child(1) > div > span:before{content: ''; position: absolute; left: 0; top: 50%; transform: translateY(-50%); background-repeat: no-repeat; background-size: contain; background-position: center;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div:nth-child(1) > div > span:nth-child(1):before{background-image: url('/public/assets/images/modelingCtrl/mail_icon.png'); width: 12px; height: 9px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div:nth-child(1) > div > span:nth-child(2):before{background-image: url('/public/assets/images/modelingCtrl/phone_icon.png'); width: 12px; height: 12px;}
.popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div:nth-child(1) > div > span:nth-child(3):before{background-image: url('/public/assets/images/modelingCtrl/web_icon.png'); width: 12px; height: 12px;}
.popUpBox .companyInformation_popUp .moreViewBtn{position: absolute; right: 40px; bottom: 40px; font-size: 16px; font-weight: 400; color: #999999; background-color: transparent; border: none; padding-right: 15px;}
.popUpBox .companyInformation_popUp .moreViewBtn i{position: absolute; right: 0; top: 50%; transform: translateY(-50%); font-size: 7px;}
.popUpBox .companyInformation_popUp .moreViewBtn:hover{color: #1B6ADD; text-decoration: underline;}
.popUpBox .companyInformation_popUp .moreViewBtn:hover i{color: #1B6ADD;}


@media (max-width: 1280px){
  .popUpBox > div{height: 500px;}
  .popUpBox .popUp_1 .contentBox .content,
  .popUpBox .popUp_1 .contentBox .content_3,
  .popUpBox .popUp_1 .contentBox .content.content_6,
  .popUpBox .popUp_2 .contentBox .content_1,
  .popUpBox .popUp_2 .contentBox .content_4,
  .popUpBox .popUp_2 .contentBox .content_5,
  .popUpBox .popUp_2 .contentBox .content_6,
  .popUpBox .popUp_2 .contentBox .content_2,
  .popUpBox .popUp_2 .content_7,
  .popUpBox .mpContent_popUp .contentBox .content,
  .popUpBox .popUp_3 fieldset,
  .popUpBox .companyInformation_popUp .contentBox{border-top: 1px solid #EEEEEE; overflow-y: scroll; height: 400px;}
  
}

@media (max-width: 1024px){
  .popUpBox > div:not(.popUp_4){width: 90%; right: 50%; transform: translateX(50%);}
  .popUpBox > .popUp_4.active{width: 90%; right: 10%; height: 100%; bottom: 0;}


  .popUpOpen_btnBox{right: 5%;}
  .popUpOpen_btnBox button{padding: 20px 30px 20px 60px;}
  .popUpOpen_btnBox button span{left: 30px;}

  .popUpBox .companyInformation_popUp .contentBox > div:nth-child(1){flex-wrap: wrap;}
  .popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(1),
  .popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(2){width: 100%;}
  .popUpBox .companyInformation_popUp .contentBox > div:nth-child(1) > div:nth-child(1){margin-bottom: 30px;}

  .popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div:nth-child(1) > div > span{display: block;}
  .popUpBox .companyInformation_popUp .contentBox > div:nth-child(3) > div:nth-child(1) > div > span:not(:first-child){margin-left: 0; margin-top: 10px;}

  .popUpBox .popUp_3 fieldset ul{flex-wrap: wrap;}
  .popUpBox .popUp_3 fieldset ul li{width: 100%;}
  .popUpBox .popUp_3 fieldset ul li:not(:nth-child(2n)){margin-right: 0;}

  .popUpBox .iconBox li:hover .txt,
  .popUpBox .iconBox li.active .txt{display: none;}
}

@media (max-width:768px){
  .popUpOpen_btnBox{justify-content: center;}
  .popUpOpen_btnBox button{padding: 10px 20px;}
  .popUpOpen_btnBox button:not(.popUp_langBtn) span{display: none;}
  .popUpOpen_btnBox .popUp_langBtn span{left: 50%; top: 50%; transform: translate(-50%, -50%);}

  .popUpBox > div .iconBox{padding: 50px 40px 0 40px;}

  .popUpOpen_btnBox{right: -242px; bottom: 280px; transform: rotate(-90deg);}

  .popUpBox .popUp_3 fieldset,
  .popUpBox .companyInformation_popUp .contentBox{padding: 20px;}

  .popUpBox .companyInformation_popUp .moreViewBtn{bottom: 10px; right: 10px;}

  
}



